
/*.addAccountButton {*/
/*    background-color: lawngreen;*/
/*    font-weight: bold;*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    width: 50%;*/
/*    height: 30px;*/
/*}*/


.mainButtons{
    width: 100%;
}

@media only screen and (max-width: 990px){
    .addAccountButton{
        width: 100%;
        font-size: 0.9rem;
    }
}

.mainContainer {
    background-color: #c5deef;
    border: 2px solid #e7e7e7;
    border-radius: 5px;
    color: white;
    font-size: 1.2rem;
}

.floatButton {
    font-size: 0.9rem;
    position: fixed;
    bottom: 12px;
    right: 12px;
    border: none;
    /*position:fixed;*/
    width: 120px;
    height: 120px;
    /*bottom:40px;*/
    /*right:40px;*/

    background-color: #7ce77d;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 1px 1px 1px #97cbc1;
}

.my-float {
    margin-top: 22px;
}

/*bootstrap table class customization*/
th {
    text-align: center;
    color: white;
    font-size: 0.9rem;
}

td {
    text-align: center;
    font-size: 0.9rem !important;
    color: white !important;
}

.paginationActive {
    background-color: #0d6efd;
    color: white;
}
.paginationButton:focus{
    background-color: transparent;
}

.paginationButton {
    background-color: transparent;
    color: white;
}

@media only screen and (max-width: 990px) {
    .floatButton {
        position: fixed;
        bottom: 12px;
        /*right: 10px;*/
        right: 12px;
        width: 70px;
        height: 70px;
    }
}

#root {
    height: 100vh;
}

.App {
    width: 100%;
    overflow-x: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rebeccapurple, lightskyblue);
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}



.cardContainer {
    /*background-color: dodgerblue;*/
    border: 2px solid #e7e7e7;
    background-image: linear-gradient( #78669d, #7c7fa1);
    border-radius: 15px;
    color: white;
    font-size: 1.2rem;
    margin-bottom: 15px;
}
.eachRow{
    border-bottom: 1px solid lightgray;

}
